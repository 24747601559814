import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loader from '../components/Loader';

const DeepPage = () => {

    const templateurl = "https://link.amphimill.com/deep?type=letypedeparage&code=lecodedepartage";
    
    const location = useLocation();

    const baseAppUrl = 'https://link.amphimill.com/';

    const idappsto = "app/amphi-mill/id6466167435";

    const [metaData, setMetaData] = useState({
        title: 'Default Title',
        description: 'Default description for the page',
        image: 'https://yt3.googleusercontent.com/MDBcwdc7-htOYmXHHm0-OFO107vE6013r9kUg9ewECSdj8PMEv10FTPmgAS_CuycK2u_sV3_=s900-c-k-c0x00ffffff-no-rj', // Default image
    });

    
    useEffect(() => {
        
         var queryString = location.search; // Get query string from URL
         
         var userAgent = navigator.userAgent || navigator.vendor || window.opera;

         

         //var baseAppUrl = 'com.amphimill.app://';

        //  const baseAppUrl = 'https://link.amphimill.com/';

         
        //  const appUrlWithParams = `${baseAppUrl}deep?${queryString.substr(1)}`;// Remove '?' from query string
         
        //  console.log("user location", `intent://deep${queryString}#Intent;scheme=https;package=com.amphimill.app;end`);

        // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        //     window.location.href = appUrlWithParams; // Use the URL with parameters for deep linking
        //     setTimeout(() => {
        //        //window.location.href = 'https://apps.apple.com/app/R5VUVQCFZ5.com.amphimill.app';
        //        window.location.href = `https://apps.apple.com/app/id6466167435`;
                
        //     }, 2000);
        // } else if (/android/i.test(userAgent)) {
        //     //window.location.href = appUrlWithParams; // Use the URL with parameters for deep linking
        //     // window.location.href = `intent://deep?${queryString.substr(1)}#Intent;scheme=https;package=com.amphimill.app;end`;
        //    // window.location.href = `intent://deep${queryString}#Intent;scheme=https;package=com.amphimill.app;end`;
        //     window.location.href = `intent://link.amphimill.com/deep${queryString}#Intent;scheme=https;package=com.amphimill.app;end`;
        //     setTimeout(() => {
        //         window.location.href = 'https://play.google.com/store/apps/details?id=com.amphimill.app';
        //     }, 2000);
        // } 
        // else {
        //     window.location.href  = 'http://localhost:3000';
        //    // window.location.href = 'https://www.amphimill.com';
            
        // }

        // Déterminez le type et le code de partage depuis l'URL
        // const params = new URLSearchParams(queryString);
        // const type = params.get('type');
        // const code = params.get('code');

        // if (type && code) {
        //     // Chargez dynamiquement les informations en fonction du type et du code
        //     //const resourceMetaData = getResourceMetaData(type, code);
        //     console.log("typpppppeee", type, code, "meta data.", metaData);
        //     // if (resourceMetaData) {
        //     //   setMetaData(resourceMetaData);
        //     // }
        //   }

       
        handleRedirection(queryString, userAgent);
    }, [location.search]); // React to changes in the search string




    //Logique de redirection
    const handleRedirection = (queryString, userAgent) => {
        const appUrlWithParams = `${baseAppUrl}deep?${queryString.substr(1)}`;// Remove '?' from query string
         
        console.log("user location", `intent://deep${queryString}#Intent;scheme=https;package=com.amphimill.app;end`);

       if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
           window.location.href = appUrlWithParams; // Use the URL with parameters for deep linking
           setTimeout(() => {
              //window.location.href = 'https://apps.apple.com/app/R5VUVQCFZ5.com.amphimill.app';
              window.location.href = `https://apps.apple.com/app/id6466167435`;
               
           }, 2000);
       } else if (/android/i.test(userAgent)) {
           //window.location.href = appUrlWithParams; // Use the URL with parameters for deep linking
           // window.location.href = `intent://deep?${queryString.substr(1)}#Intent;scheme=https;package=com.amphimill.app;end`;
          // window.location.href = `intent://deep${queryString}#Intent;scheme=https;package=com.amphimill.app;end`;
           window.location.href = `intent://link.amphimill.com/deep${queryString}#Intent;scheme=https;package=com.amphimill.app;end`;
           setTimeout(() => {
               window.location.href = 'https://play.google.com/store/apps/details?id=com.amphimill.app';
           }, 2000);
       } 
       else {
           //window.location.href  = 'http://localhost:3000';
           window.location.href = 'https://www.amphimill.com';
           
       }
    };


    //Affichage par defaut
    return (
        <>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
                <meta property="og:title" content={metaData.title} />
                <meta property="og:description" content={metaData.description} />
                <meta property="og:image" content={metaData.image} />
                <meta property="og:url" content={`https://www.amphimill.com${location.pathname}`} />
                <meta property="twitter:title" content={metaData.title} />
                <meta property="twitter:description" content={metaData.description} />
                <meta property="twitter:image" content={metaData.image} />
            </Helmet>
             <div className="d-flex justify-content-center align-items-center vh-100">
                <span>Loader</span>
                <Loader/>
              
            </div>
        </>
    );
}

export default DeepPage;