import React, { useState } from 'react';
// import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

const ShareModal = ({memberParam, showModal, openModal, sectionId}) => {
  // Affichez le modal uniquement si `show` est vrai
console.log("composant modal", memberParam.profilName);

  const [show, setShow] = useState(false);

  const [copied, setCopied] = useState(false);
  const baseURL = "https://www.amphimill.com";
  
  // Créez le lien dynamique vers la section
  const shareLink = `${baseURL}/#${sectionId}/${memberParam.profilName}`;

  // Fonction pour copier le lien dans le presse-papier
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Affiche le message "Copié!" pendant 2 secondes
  };

  const handleClose = () => openModal(false);
  const handleShow = () => setShow(true);

  const closeModal= () => {
    openModal
  }

  return (
    <>

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Partagez le profil de {memberParam.profilName}</h2>
            <div className="tm-team text-center">
                <div className="tm-team-image">
                                    <div className="tm-team-imageinner">
                                        <img src={memberParam.srcImage} alt="appmyil team member" height={200} width={200}/>
                                    </div>
                                </div>
            </div>
            <p>Vous pouvez partager ce profil avec les boutons ci-dessous :</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ul className="social-share-buttons">
            <li>
                <a href="https://wa.me">
                <i className="zmdi zmdi-whatsapp"></i> Partager sur Whatsapp
                </a>
            </li>
            <li>
                <a href="https://www.facebook.com/sharer/sharer.php?u=https://votresite.com">
                <i className="zmdi zmdi-facebook"></i> Partager sur Facebook
                </a>
            </li>
            {/* <li>
                <a href="https://www.facebook.com/sharer/sharer.php?u=https://votresite.com">
                <i className="zmdi zmdi-facebook"></i> Partager sur Facebook
                </a>
            </li> */}
            <li>
                <a href="https://twitter.com/intent/tweet?url=https://votresite.com">
                <i className="zmdi zmdi-twitter"></i> Partager sur Twitter
                </a>
            </li>
            <li>
                <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://votresite.com">
                <i className="zmdi zmdi-linkedin"></i> Partager sur LinkedIn
                </a>
            </li>
            </ul>
            <div className="copy-section">
            <div className="link-display">
                <code>{shareLink}</code>
            </div>
            <button onClick={handleCopyLink} className="copy-button">
                {copied ? "Lien copié!" : "Copier le lien"}
            </button>
            </div>
            <div></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShareModal;

