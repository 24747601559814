import React from 'react';
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// import logo from '../assets/images/logo-white.png';

const languages = [
    {
        code: 'en',
        name:'English',
        country_code: 'gb'
    },
    {
        code: 'fr',
        name:'Français',
        country_code: 'fr'
    },
    
];

const GlobeIcon = ({width = 24, height= 24}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" className="bi bi-globe" viewBox="0 0 16 16">
  <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
</svg>
);

const BarIcon = ({width = 24, height= 24}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bricks" viewBox="0 0 16 16">
    <path d="M0 .5A.5.5 0 0 1 .5 0h15a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H2v-2H.5a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 .5 6H2V4H.5a.5.5 0 0 1-.5-.5v-3zM3 4v2h4.5V4H3zm5.5 0v2H13V4H8.5zM3 10v2h4.5v-2H3zm5.5 0v2H13v-2H8.5zM1 1v2h3.5V1H1zm4.5 0v2h5V1h-5zm6 0v2H15V1h-3.5zM1 7v2h3.5V7H1zm4.5 0v2h5V7h-5zm6 0v2H15V7h-3.5zM1 13v2h3.5v-2H1zm4.5 0v2h5v-2h-5zm6 0v2H15v-2h-3.5z"/>
  </svg>
);

function myFunction() {
    var x = document.getElementById("myLinks");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
}



function Header () {

    // React.useEffect(()=> {
    //     $('.mobile-nav').meanmenu()
    // }, [])

    // const currentLanguageCode = cookies.get('i18next') || 'en'
    const {t} = useTranslation();

    return (
        <>
        <div className="mobile-container">

{/* <!-- Top Navigation Mobile Menu --> */}
                <div className="topnav">
                    <a href="#home" className=""> <img src="/assets/images/logo-white1.png" alt="appmyil logo" /></a>
                    <div id="myLinks">
                        <a href="#tm-area-heroslider" onClick={()=>myFunction()}>{t('menu_home')}</a>
                        <a href="#tm-area-features" onClick={()=>myFunction()}>{t('menu_feature')}</a>
                        <a href="#tm-area-screenshots" onClick={()=>myFunction()}>{t('menu_screenshot')}</a>
                        <a href="#tm-area-team" onClick={()=>myFunction()}>{t('menu_team')}</a>
                        <a href="#tm-area-pricing" onClick={()=>myFunction()}>{t('menu_price')}</a>
                        <a href="#tm-area-blog" onClick={()=>myFunction()}>{t('menu_blog')}</a>
                        <a href="#tm-area-contact" id="unique" onClick={()=>myFunction()}>{t('menu_conact')}</a>
                        <a> 
                             {/* <GlobeIcon/> */}
                             <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    
                                {/* <GlobeIcon className="global-icon"/> */}
                                <a className="nav-link dropdown-toggle" href="#" >
                                    <i className="fas fa-globe"></i> <GlobeIcon className="global-icon"/>
                                </a>
                                
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                <ul className="navbar-nav ml-auto">
                                {languages.map(({code, name, country_code}) => (
                                                <li key={country_code}li className="nav-item dropdown">
                                                    <button className='btn-nt-bt' onClick={()=>{i18next.changeLanguage(code); myFunction() }}>
                                                        <span className={`flag-icon flag-icon-${country_code} mx-2`}>

                                                        </span>
                                                        {name}
                                                    </button>
                                                </li>
                                            ))}
                                    {/* <li className="nav-item dropdown">
                                        
                                        <a className="dropdown-item" href="#">Français</a>
                                        <a className="dropdown-item" href="#">Anglais</a>
                                    </li> */}
                                </ul>
                            </div>
                        </a>
                    </div>
                    
                    <a  className="icon" onClick={()=>myFunction()}>
                        {/* <i className="fa fa-bars"></i> */}
                        <BarIcon/>
                    </a>
                    
                </div>

            </div>
            
            {/* <!-- Header Area --> */ }
            <div className="tm-header tm-header-fixed tm-sticky-header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-9 col-9">
                            <a className="tm-header-logo" href="#">
                                <img src="/assets/images/logo-white.png" alt="appmyil logo" />
                            </a>
                        </div>
                        <div className="col-lg-9 col-md-3 col-3">
                            <nav className="tm-navigation tm-header-navigation">
                                <ul>
                                    <li className="current"><a href="#tm-area-heroslider">{t('menu_home')}</a></li>
                                    <li><a href="#tm-area-features">{t('menu_feature')}</a></li>
                                    <li><a href="#tm-area-screenshots">{t('menu_screenshot')}</a></li>
                                    <li><a href="#tm-area-team">{t('menu_team')}</a></li>
                                    {/* <li><a href="#tm-area-pricing">{t('menu_price')}</a></li> */}
                                    <li><a href="#tm-area-blog">{t('menu_blog')}</a></li>

                                    {/* <li className="tm-navigation-dropdown"><a href="#tm-area-blog">{t('menu_blog')}</a> */}
                                        {/* <ul>
                                            <li><a href="blog.html">Blog Grid</a></li>
                                            <li><a href="blog-without-sidebar.html">Blog Grid Without Sidebar</a></li>
                                            <li><a href="blog-details.html">Blog Details</a></li>
                                            <li><a href="blog-details-without-sidebar.html">Blog Details Without Sidebar</a></li>
                                        </ul> */}
                                    {/* </li> */}
                                    
                                    <li><a href="#tm-area-contact">{t('menu_conact')}</a></li>
                                    <li className="tm-navigation-dropdown"><a href="#">
                                        <GlobeIcon/>
                                    </a>
                                        <ul>
                                            {languages.map(({code, name, country_code}) => (
                                                <li key={country_code}>
                                                    <button className='btn-nt-bt' onClick={()=>{i18next.changeLanguage(code); myFunction()}}>
                                                        <span className={`flag-icon flag-icon-${country_code} mx-2`}>

                                                        </span>
                                                        {name}
                                                    </button>
                                                </li>
                                            ))}
                                            {/* <li><a href="#">Français</a></li>
                                            <li><a href="#">Anglais</a></li> */}
                                           
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    {/* <!-- Simulate a smartphone / tablet --> */}
                    
                    {/* <div className="tm-mobilenav">
                    
                    </div> */}
                </div>
            </div>
            

            {/* <!--// Heroslider Area --> */}

            {/* <!-- Heroslider Area --> */}
            <div id="tm-area-heroslider" className="tm-heroslider">
                <div className="tm-heroslider-inner">
                    <img src="assets/images/heroslider-overlay-shape.png" alt="heroslider ovelay" className="tm-heroslider-ovelayshape" />
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-8 col-lg-7 order-2 order-lg-1">
                                <div className="tm-heroslider-content">
                                    <h1>{t('welcome_message1')}<br/>{t('welcome_message2')}</h1>
                                    <p>{t('slogan')}</p>
                                    <div className="tm-buttongroup">
                                        <a href="https://play.google.com/store/apps/details?id=com.amphimill.app" className="tm-button tm-button-lg tm-button-white tm-button-transparent"><i
                                                className="zmdi zmdi-android"></i><span>Google Play</span></a>
                                        <a href="https://apps.apple.com/fr/app/amphi-mill/id6466167435" className="tm-button tm-button-lg tm-button-white tm-button-transparent"><i
                                                className="zmdi zmdi-apple"></i><span>IOS App Store</span></a>
                                    </div>
                                    <a href="#tm-area-features" className="tm-heroslider-scrolldown">
                                        <i className="zmdi zmdi-square-down"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5 order-1 order-lg-2">
                                <div className="tm-heroslider-mobileshow">
                                    <img src="assets/images/heroslider-mobile-show.png" alt="appmyil mobile" />
                                    <div className="tm-heroslider-mobileshowanim">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--// Heroslider Area --> */}
        </>
    )
}

export default Header